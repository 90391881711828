@font-face {
    font-family: 'MyFont'; /* Имя шрифта, которое вы будете использовать в CSS */
    src: url('/public/fonts/AvenirNextCyr-Medium.woff2') format('woff2'); /* Убедитесь, что путь корректный */
    font-weight: normal; /* Укажите нужный вес шрифта */
    font-style: normal; /* Укажите нужный стиль шрифта (normal, italic и т.д.) */
}

*{
    font-family: 'MyFont', sans-serif;
}

.story-container {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 0px;
    font-family: 'Arial', sans-serif;
    max-width: 100%; /* Ширина контейнера 100% */
    width: 100%;     /* Убираем выход за края с использованием 100% */
}

.cover-image {
    position: relative; /* Позволяет использовать абсолютное позиционирование для текста */
    margin: 0 auto;  /* Центрируем изображение */
    width: 100%;
    max-width: 100%; /* Убираем выход за границы */
}

.cover-image img {
    padding-top: 40px;
    width: 100%;       /* Картинка занимает всю ширину контейнера */
    max-width: 400px;  /* Ограничиваем максимальную ширину для мобильных */
    border-radius: 150px 150px 0 0;
}

.text-overlay {
    position: absolute; /* Абсолютное позиционирование для текста */
    bottom: -6%; /* Прикрепляем текст к низу изображения */
    left: 50%; /* Центрируем текст по горизонтали */
    transform: translateX(-50%); /* Корректируем центрирование */
    text-align: center; /* Центрируем текст внутри контейнера */
    padding-bottom: 40px; /* Добавляем отступы для удобства */
    background: transparent;
    width: calc(100% - 20px); /* Текст занимает всю ширину с отступами */
    box-sizing: border-box; /* Учитываем отступы в ширине */
    overflow-wrap: break-word; /* Позволяет переносить длинные слова */
    white-space: normal; /* Позволяет тексту переноситься */
}


.title {
    font-size: 28px;    /* Подстраиваем размер текста */
    margin: 5px 0; /* Убираем лишние отступы */
}

.genre {
    font-size: 20px;    /* Жанр в более крупном шрифте */
    color: rgb(170,39,49);
}

.tags {
    display: flex; /* Используем flexbox для контейнера */
    flex-wrap: wrap; /* Позволяет элементам переноситься на новую строку */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    font-size: 14px;
    color: rgb(170, 39, 49);
    max-width: 100%;
    justify-content: center;
}

.tags span {
    margin: 0 5px; /* Отступы между спанами для визуального отделения */
}


.description {
    font-size: 16px;
    line-height: 1.5;
    hyphens: auto;
    text-align: justify;
    margin: 20px auto;
    max-width: 100%;    /* Описание на всю ширину экрана */
    padding: 0 25px;    /* Добавляем небольшие внутренние отступы */
}

.read-button {
    background-color: #000;
    color:rgb(170,39,49);
    border: solid rgb(170, 39, 49) 1px;
    padding: 10px 20px;
    margin-bottom: 30px;
    font-size: 16px;     /* Уменьшаем шрифт для кнопки */
    border-radius: 50px;
    cursor: pointer;
    width: 100%;         /* Кнопка на всю ширину контейнера */
    max-width: 350px;    /* Ограничиваем максимальную ширину для удобства */
}

.read-button:hover {
    background-color: #c9302c;
}

.separator {
    justify-content: center;
    height: 1px; /* Высота разделителя */
    background-color: #fff; /* Цвет разделителя */
    margin: 0 25px 0 25px; /* Отступы сверху и снизу */
}


.expand-text {
    color: rgb(170,39,49); /* Цвет текста для "развернуть текст" */
    cursor: pointer; /* Указатель при наведении */
    display: inline-block; /* Чтобы работала анимация */
    margin-top: 10px; /* Отступ сверху для лучшего отображения */
    font-weight: bold; /* Жирный текст */
    transition: color 0.3s; /* Плавный переход цвета */
}

.expand-text:hover {
    color: #c9302c; /* Цвет при наведении */
}

.description {
    transition: max-height 0.5s ease; /* Плавная анимация высоты */
    overflow: hidden; /* Скрываем переполнение */
}

/* Если необходимо, добавьте дополнительные стили для текста в развернутом состоянии */
