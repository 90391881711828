/* Общие стили для страницы */
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #121212; /* Ночная тема по умолчанию */
    color: #e0e0e0; /* Цвет текста */
    overflow: visible;
}

/* Контейнер для основного контента */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/* Стили для выпадающего списка и переключателя темы */
.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.custom-dropdown {
   width: auto;
    height: auto;
    text-align: center;
}

.dropdown-select {
    padding: 9px;
    font-size: 12px;
    border: 1px solid #888; /* Цвет границы */
    border-radius: 4px;
    background-color: transparent;
    color: #888;
    appearance: none; /* Убираем стандартный стиль */
}

.dropdown-select option {
    text-align: center; /* Выравнивание текста внутри option элементов */
}

.dropdown-select:focus {
    outline: none;
    border-color: #888;
}

.theme-toggle-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #e0e0e0;
    transition: color 0.3s;
}

.theme-toggle-button:hover {
    color: #aaa;
}

/* Стили для отображения контента книги */
.book-content {
    white-space: pre-wrap; /* Сохраняем переносы строк */
    word-wrap: normal; /* Переносим длинные слова */
    line-height: 1.6; /* Интерлиньяж для читаемости */
    font-size: 16px; /* Размер шрифта */
    padding: 20px; /* Отступы внутри блока */
    background-color: #1e1e1e; /* Фон блока */
    border-radius: 8px; /* Округлые углы */
    max-height: 81vh; /* Ограничение по высоте */
    overflow-y: auto; /* Скроллинг для длинных текстов */
    overflow-x: hidden; /* Прячем горизонтальный скролл */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень для блока */
    opacity: 1; /* Полная видимость по умолчанию */
    transition: opacity 1s ease-in-out; /* Плавное появление */
    hyphens: auto; /* Перенос слов с переносами */
    margin: 0 auto; /* Центрирование блока по горизонтали */
    max-width: 1000px; /* Ограничение ширины блока для лучшего восприятия */
}


/* Появление текста при загрузке */
.book-content.loaded {
    opacity: 1;
}

@media (max-width: 600px) {
    /* Общие стили для блока дропдауна и иконки */
    .header {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    /* Кнопка переключения темы */
    .theme-toggle-button {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        padding: 0; /* Убираем лишние отступы */
    }

    /* Стили для SVG-иконки */
    .sun {
        width: 24px; /* Размер иконки */
        height: 24px;
        display: block; /* Делаем элемент блочным */
        visibility: visible; /* Убедимся, что элемент виден */
        filter: none; /* Отключаем все фильтры для проверки */
        background: none; /* Убираем любые фоны */
        border: none; /* Убираем границы */
    }
}


/* Стили для scroll */
.book-content::-webkit-scrollbar {
    width: 2px; /* Ширина полосы прокрутки */
}

.book-content::-webkit-scrollbar-track {
    background: transparent; }

.book-content::-webkit-scrollbar-thumb {
    background: #2e2e2e;
    border-radius: 10px;
}

.book-content::-webkit-scrollbar-thumb:hover {
    background: #555; /* Цвет ползунка при наведении */
}

/* Светлая тема */
.light-theme {
    background-color: #f2e2c9;
    color: #000000;
}

.light-theme .book-content {
    background-color: #f2e2c9;
}

.light-theme .dropdown-select {
    background-color: #f2e2c9;
    color: #515151;
    border-color: #515151; /* Цвет границы для светлой темы */
}

.light-theme .theme-toggle-button {
    color: #000000;
}

.light-theme .theme-toggle-button:hover {
    color: #333;
}

.sun {
    filter: invert(48%) sepia(79%) saturate(0%) hue-rotate(86deg) brightness(118%) contrast(119%);
    transition-duration: 1s;
    margin-left: 5px;
}

.moon{
    transition-duration: 1s;
}

.light-theme {
    .font-size-button, .alignment-button {
        color: #515151;
    border: 1px solid #989898;
    }
}

.font-size-button, .alignment-button {
    display: flex;
    width: 39px;
    height: 38px;
    margin-left: 5px;
    color: #ffffff;

    border-radius: 5px;
    background-color: transparent;
}

.font-size-controls{
    display: flex;
    margin: 5px;
}

.alignment-controls{
    display: flex;
}

.font-size-button:active, .alignment-button:active{
background-color: #b802f6
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: 1px solid #888;
}
